//
// Custom
//

@use 'sass:map';

.scroll-margin {
    scroll-margin-top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.sticky-top-size {
    top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}


.acres-logo {
    width: 80px;
}

.cutom-form-title {
    font-size: 1.1rem;
}

.db-btn {
    margin-top: 24px;

    button {
        min-width: 122px;
    }
}

.highlight-color {
    color: orange;
    font-weight: bold;
}

.highlight-bg {
    color: white;
    background: orange;
}

.scroll {
    border: 1px solid gray;
    padding: 10px;
    overflow: hidden;
    overflow-y: scroll;
    max-height: 200px;
    width: 50%;
    cursor: pointer;
    list-style: none;
    border-radius: 8px;

}

.notesAtt{
    overflow-y: scroll;
    max-height: 185px;
}

.scrolls {
    width: 100%;
    padding: 0.3rem 0.5rem;
}

.scrolls:hover {
    background-color: gray;
}

.bb-1 {
    border-bottom: 1px solid gray;
}

.align-right {
    text-align: right;
}

.schedule-btn-group {
    text-align: right;
    margin-top: 1em;

    a.btn {
        margin-right: 16px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.invoice-table {
    tr {
        td {
            padding: 10px 16px;

            a {
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.mt-25 {
    margin-top: 25px;
}

.weekDays-selector {
    input {
        display: none !important;
    }
}

.weekDays-selector input[type=checkbox]+label {
    display: inline-block;
    border-radius: 6px;
    color: #1d1f27;
    background: #dddddd;
    height: 40px;
    width: 30px;
    margin-right: 3px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
}

.weekDays-selector input[type=checkbox]:checked+label {
    background: #4d65f4;
    color: #ffffff;
}

.modern-design {
    .aside {
        .user {
            .user-avatar {
                display: none;
            }
        }
    }
}

.action-btns {
    text-align: right;

    button {
        margin-left: 12px;
    }
}

#dhpp2,
#lepto2,
#kennel2 {
    .card {
        margin-bottom: 0;
    }
}

.pending-reg {
    @media (max-width: 768px) {
        margin-top: 1em;
        margin-bottom: 0;
    }
}

.rbc-calendar .rbc-event-content .d-flex.justify-content-between.px-2.rounded {
    margin-bottom: 2px;
}

.form-control {
    &:focus {
        border-color: transparent;
    }
}

.form-select {
    &:focus {
        border-color: transparent;
    }
}

// Theme style update

body {
    background-color: #f5f5f5 !important;
}

.subheader {
    -webkit-box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
}

.card,
.modern-design .subheader,
.dropdown-menu,
.modern-design .header,
.modern-design .aside {
    border-radius: .428rem !important;
}

.card,
.modern-design .header {
    -webkit-box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 10%);
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 10%);
}

.btn,
.rounded,
.form-control,
.form-select,
.rbc-time-view,
.rbc-month-view,
.navigation .navigation-link-pill,
.popover {
    border-radius: 0.358rem !important;
}

.form-control:focus {
    border: 1px solid #d8d6de;
    box-shadow: none;
}

.form-control,
.form-select {
    border: 1px solid #d8d6de;
}

.table-modern>tbody>tr> :first-child,
.table-modern>thead>tr> :first-child,
.page-item:first-child .page-link {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.table-modern>tbody>tr> :last-child,
.table-modern>thead>tr> :last-child,
.page-item:last-child .page-link {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.table-modern>tbody>tr>* {
    padding: 0.5rem 1rem;
}


.offcanvas-header {
    background: #f4f4f4;
}

.offcanvas-body {
    margin-top: 1em;
}

.modal-header {
    border-bottom: 1px solid #dee2e6;
    background-color: #f4f4f4;
}

.weekDays-selector {
    .form-check {
        padding-left: 0;
        padding-right: 1em;
    }
}

.popover-body {

    input[type="month"] {
        padding: 6px 10px;
        border: 1px solid #d8d6de;
    }
}


.card-header {
    padding: 1rem 1.5rem;
    min-height: 46px;
}

.offcanvas-backdrop {
    background-color: rgb(0 0 0 / 47%);
}

.list-days {
    background-color: #f8f9fa;
    border: 1px solid #d8d6de;
    border-radius: 0.358rem;
    padding: 14px 8px;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
}


.modal-body,
.modal-footer {
    background-color: #ffffff;
}

.table {
    thead {
        tr {
            background-color: #f9f9f9;
        }
    }

    tbody {
        tr {
            border-top: 1px solid #d9d9d9;
        }
    }
}


[theme=dark]:root {
    .table {
        thead {
            tr {
                background-color: #3d404a;
            }
        }

        tbody {
            tr {
                border-top: 1px solid #606060;
            }
        }
    }
}

.dropdown {
    .btn.btn-light-dark {
        border-color: #cdcdd1;
    }
}

.route-color {
    width: 90px;
    border: 1px solid #ccc;
    height: 34px;
}

.notes-date {
    font-weight: 600;
    margin-bottom: 6px;
    display: inline-block;

    .dropdown-item-wrapper {
        .btn.btn-dark {
            color: #808191;

            &:hover {
                color: #12614b;
            }
        }
    }
}

.form-control-color::-webkit-color-swatch {
    border-radius: 0;
}

input[type="color" i]::-webkit-color-swatch-wrapper {
    width: 50%;
}

.map-wrap {
    height: 600px;
    margin-bottom: 2em;
    border-radius: 0.428rem;
    overflow: hidden;
}

.align-right {
    .dropdown {
        text-align: right;
    }
}

.vaccinationTable{
    thead, tbody {
        tr {
            th, td {
                width: 20%;
            }
        }
    }
}
.d-content{
    display: contents !important;
}

.swal2-success, .swal2-warning, .swal2-success-ring{
    border-color: #12614b !important;
    color: #12614b !important;
}

.swal2-confirm{
    background-color: #12614b !important;
}

.swal2-success-line-tip, .swal2-success-line-long{
    background-color: #12614b !important;
}

.select__clear-indicator{
    display: none !important;
}

.filter-module{
    min-height: 680px;
    @media (max-width: 768px) {
        min-height: 350px;
    }
}

.ruleTable{
 th, td {
        border: 1px solid black;
        border-collapse: collapse;
      }
}

.mobileViewStaffPage{
    min-height: 680px;
    @media (max-width: 768px) {
        min-height: 0;
    }
}

@media (max-width: 768px) {
    .mobileHeight{
        height: 55vh;
    }
    .mobileLeaveBtn{
        display: block !important;
    }
    .mobileBlock{
        display: none !important;
    }
    .mobileOrder{
        flex-direction: column-reverse;
        padding-top: 0;
        padding-bottom: 0;
    }
    .mobilePadding{
        padding-top: 20px;
    }
}


@media only screen and (max-width: 600px)  {
    .table-re{
    width: 50rem;
    }
    .driveAvailability{
        flex-direction: column;
    }
    .driveAvailability > div{
        padding-left: 0 !important;
    }
}
